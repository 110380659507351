import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";

import Home from './Home';
import Contact from './Contact';
import Remove from './Remove';
import Policy from './Policy';

function App() {
  return (
    <Router>
        <Switch>
        <Route exact path="/"><Home /></Route>
        <Route path="/contact"><Contact /></Route>
        <Route path="/remove"><Remove /></Route>
        <Route path="/policy"><Policy /></Route>
        <Route component={Home} />
        </Switch>
    </Router>
  );
}

export default App;
